import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import moment from 'moment'
import useGet from 'hooks/useGet'
import EllipseIcon from 'assets/svg/HPSvgs/EllipseIcon'
import {
  Container,
  Wrapper,
  Heading,
  CardsContainer,
  BoxSection,
  ImageWrapper,
  ContentSection,
  CategoryWrapper,
  DateAndCategorySection,
  DateWrapper,
  IconWrapper,
  Description,
  ReadMoreText,
  MobileContentGrid,
} from 'styles/hpLayout/empoweringWomenFarmers'

const EmpoweringWomenFarmers = () => {
  const history = useHistory()
  const { data: blogsdata, refetch: fetchBlogs } = useGet('allBlogs', `/api/blogs`)

  useEffect(() => {
    fetchBlogs()
  }, [])

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const handleClick = (id) => {
    history.push(`/blog/${id}`)
  }

  const topBlogs = blogsdata?.data?.length >= 0 ? blogsdata?.data.slice(0, 3) : []

  return (
    <Container>
      <Wrapper>
        <Heading>Empowering Women Entrepreneurs</Heading>
        <CardsContainer>
          {topBlogs?.map((item, index) => (
            <BoxSection key={index} onClick={() => handleClick(item?.id)}>
              <ImageWrapper>
                <img src={item?.bannerImage} alt={item?.title} loading="lazy" />
              </ImageWrapper>
              <ContentSection>
                <DateAndCategorySection>
                  <DateWrapper>{moment(item?.updatedAt).format('MMMM Do, YYYY')}</DateWrapper>
                  <IconWrapper>
                    <EllipseIcon />
                  </IconWrapper>
                  <CategoryWrapper>{item?.category}</CategoryWrapper>
                </DateAndCategorySection>
                <Description>{item?.title}</Description>
                <ReadMoreText onClick={() => handleClick(item?.id)}>Read More</ReadMoreText>
              </ContentSection>
            </BoxSection>
          ))}
        </CardsContainer>
        <MobileContentGrid className="features-slider">
          <Slider {...sliderSettings}>
            {blogsdata?.data.map((item, index) => (
              <BoxSection key={index} onClick={() => handleClick(item?.id)}>
                <ImageWrapper>
                  <img src={item?.bannerImage} alt={item?.title} loading="lazy" />
                </ImageWrapper>
                <ContentSection>
                  <DateAndCategorySection>
                    <DateWrapper> {moment(item?.updatedAt).format('MMMM Do, YYYY')}</DateWrapper>
                    <CategoryWrapper>{item?.category}</CategoryWrapper>
                  </DateAndCategorySection>
                  <Description>{item?.title}</Description>
                  <ReadMoreText onClick={() => handleClick(item?.id)}>Read More</ReadMoreText>
                </ContentSection>
              </BoxSection>
            ))}
          </Slider>
        </MobileContentGrid>
      </Wrapper>
    </Container>
  )
}

export default EmpoweringWomenFarmers
