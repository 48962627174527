import styled from 'styled-components'
import FeedbackImage from 'assets/images/feedback.jpg'
import { theme } from 'styles/Theme/theme'

export const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`

export const LeftContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background: url(${FeedbackImage}) no-repeat center center / cover;
`
export const Logo = styled.img`
  position: absolute;
  width: 114px;
  top: 26px;
  left: 37px;
  object-fit: contain;
  @media (max-width: 450px) {
    width: 110px;
  }
`

export const RightContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 579px;
  height: 696px;
  padding: 10px 20px;
`
export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`
export const Heading = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`
export const Title = styled(Heading)`
  font-size: 14px;
  line-height: 22px;
  a {
    font-weight: 400;
  }
  .astreik {
    color: ${theme.SECONDARYERRORCOLOR};
  }
`
export const SubTitle = styled(Title)`
  font-weight: 400;
`
export const CheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`
export const TextArea = styled.textarea`
  border: 1px solid ${theme.SECONDARYBORDERCOLOR};
  border-radius: 8px;
`

export const ButtonContainer = styled.div`
  margin-top: 20px;
`
export const HelpText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  > span {
    color: ${theme.PRIMARYCOLOR};
  }
`

export const ReviewsMainContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  margin-top: 120px;
  overflow-y: auto;
  @media (max-width: 450px) {
    margin-top: 60px;
  }
`
export const ItemsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const ItemDetailSection = styled.div`
  display: flex;
  gap: 20px;
`
export const ImageWrapper = styled.div`
  img {
    width: 60px;
    height: 60px;
  }
`
export const ItemName = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
`
export const ReviewRightContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const ErrorMessage = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.ERROR};
  padding: 2px 0;
  text-align: start;
`
export const ReviewFormSubTitle = styled(Title)`
  font-weight: 600;
  font-size: 16px;
`
