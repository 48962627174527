import React, { useEffect, useContext } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import TestYourCatalogue from 'components/TestYourCatalogue/testYourCatalogue'
import Login from 'components/auth/login/login'
import SignUp from 'components/auth/sign-up/signUp'
import PageNotFound from 'components/page-not-found/pageNotFound'
import ForgetPassword from 'components/auth/forget-password'
import Feedback from 'views/Feedback'
import Providers from 'pages/providers'
import Cookies from 'js-cookie'
import instance from 'services/axiosInstance'
import { deleteAllCookies } from 'utils/cookies'
import { ToastContext } from 'context/toastContext'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
// import AppLayout from 'components/appLayout'
import ProductRoutes from 'components/application/product-list/ProductRoutes'
import ProductDetails from 'components/application/product-list/product-details/productDetails'
import Cart from 'components/application/cart/cart'
import SearchProducts from 'components/SearchProducts/searchProducts'
import BrandRoutes from 'components/brand/BrandRoutes'
import TrackOrder from 'pages/track-order'
import AboutUsPage from 'pages/about-us'
import ReturnAndRefund from 'pages/returns-refund'
import TermsAndConditions from 'pages/terms-conditions'
import ShippingPolicy from 'pages/shipping-policy'
import CancellationPolicy from 'pages/cancellation-policy'
import ContactUs from 'pages/contact-us'
import FAQs from 'pages/faq'
import BharthamPurchaseProtection from 'pages/purchase-protection'
import ComplaintDetail from 'components/application/my-tickets/complaintDetail'
import MyOrder from 'components/myOrders'
import WishlistDetails from 'components/whislist'
import UserProfile from 'views/UserProfile'
import Stores from 'pages/stores'
import Checkout from 'components/checkout/checkout'
import MyTickets from 'components/application/my-tickets/myTickets'
import OrderDetails from 'components/orders/orderDetails/orderDetails'
import ProfileRoutes from 'profileRoutes' // Import ProfileRoutes
import TrackingComponent from 'trackingComponent'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import HpLayout from 'components/hpLayout'
import Home from 'components/hpLayout/home'
import Blogs from 'pages/blogs'
import Categories from 'pages/categories'
import BlogDetailPage from 'components/hpLayout/blogDetails'
import RatingsAndReviews from 'views/RatingsAndReviews'

// import { BrowserRouter as Routes } from 'react-router-dom'
// import ProfileLayout from 'components/profileLayout'

const OndcRoutes = () => {
  const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  //need to add GA as of application routes
  const dispatch = useContext(ToastContext)
  const REFRESH_INTERVAL = 55 * 60 * 1000

  const token = Cookies.get('token')
  const refreshToken = Cookies.get('refreshToken')

  useEffect(() => {
    if (refreshToken && token) {
      const getRefreshToken = async () => {
        try {
          const response = await instance.get('/clientApis/refresh-token', {
            headers: {
              refreshtoken: `${refreshToken}`,
            },
          })

          const newToken = response?.data?.token
          Cookies.set('token', newToken)
          localStorage.setItem('token', newToken)
        } catch (error) {
          dispatch({
            type: toast_actions.ADD_TOAST,
            payload: {
              id: Math.floor(Math.random() * 100),
              type: toast_types.warning,
              message: 'You are logged out, please log in again.',
            },
          })

          deleteAllCookies()
          localStorage.clear()
          history.push('/')
        }
      }

      // Call refreshToken immediately, then set interval
      getRefreshToken()

      const intervalId = setInterval(getRefreshToken, REFRESH_INTERVAL)

      // Cleanup interval on component unmount
      return () => clearInterval(intervalId)
    }
  }, [token, refreshToken])

  return (
    <Router>
      <TrackingComponent />
      <Switch>
        {/* <Route path="/" component={Application} /> */}
        <Route path="/login" component={Login} />
        <Route path="/sign-up" component={SignUp} />
        <Route path="/forget-password" component={ForgetPassword} />
        {/* <Route path="/application" component={Application} /> */}
        <Route path="/testYourCatalogue" component={TestYourCatalogue} />
        <Route path="/feedback" component={Feedback} />

        <Route exact path="/">
          <HpLayout>
            <Home />
          </HpLayout>
        </Route>
        <Route exact path="/products">
          <HpLayout>
            <ProductRoutes />
          </HpLayout>
        </Route>
        <Route path="/products/:id">
          <HpLayout>
            <ProductDetails />
          </HpLayout>
        </Route>
        <Route path="/cart">
          <HpLayout>
            <Cart />
          </HpLayout>
        </Route>
        <Route path="/product-type">
          <HpLayout>
            <SearchProducts />
          </HpLayout>
        </Route>
        <Route path="/brand">
          <HpLayout>
            <BrandRoutes />
          </HpLayout>
        </Route>
        <Route exact path="/checkout">
          <HpLayout>
            <Checkout />
          </HpLayout>
        </Route>
        <Route path="/profile">
          <HpLayout>
            <ProfileRoutes /> {/* Add ProfileRoutes for profile-related routes */}
          </HpLayout>
        </Route>
        <Route path="/order/:orderId">
          <HpLayout>
            <OrderDetails />
          </HpLayout>
        </Route>
        <Route path="/complaints">
          <HpLayout>
            <MyTickets />
          </HpLayout>
        </Route>
        <Route path="/complaint/:issueId">
          <HpLayout>
            <ComplaintDetail />
          </HpLayout>
        </Route>
        <Route path="/orders">
          <HpLayout>
            <MyOrder />
          </HpLayout>
        </Route>
        <Route path="/wishlist">
          <HpLayout>
            <WishlistDetails />
          </HpLayout>
        </Route>
        <Route path="/user-info">
          <HpLayout>
            <UserProfile />
          </HpLayout>
        </Route>
        <Route path="/providers">
          <HpLayout>
            <Providers />
          </HpLayout>
        </Route>
        <Route path="/stores">
          <HpLayout>
            <Stores />
          </HpLayout>
        </Route>
        <Route path="/track-order/:orderId">
          <HpLayout>
            <TrackOrder />
          </HpLayout>
        </Route>
        <Route path="/about-us">
          <HpLayout>
            <AboutUsPage />
          </HpLayout>
        </Route>
        <Route path="/returns-refund">
          <HpLayout>
            <ReturnAndRefund />
          </HpLayout>
        </Route>
        <Route path="/terms-conditions">
          <HpLayout>
            <TermsAndConditions />
          </HpLayout>
        </Route>
        <Route path="/shipping-policy">
          <HpLayout>
            <ShippingPolicy />
          </HpLayout>
        </Route>
        <Route path="/cancellation-policy">
          <HpLayout>
            <CancellationPolicy />
          </HpLayout>
        </Route>
        <Route path="/contact-us">
          <HpLayout>
            <ContactUs />
          </HpLayout>
        </Route>
        <Route path="/faq">
          <HpLayout>
            <FAQs />
          </HpLayout>
        </Route>
        <Route path="/purchase-protection">
          <HpLayout>
            <BharthamPurchaseProtection />
          </HpLayout>
        </Route>
        <Route path={'/blogs'}>
          <HpLayout>
            <Blogs />
          </HpLayout>
        </Route>
        <Route path={'/ratings/:id'}>
          <HpLayout>
            <RatingsAndReviews />
          </HpLayout>
        </Route>
        <Route path={'/blog/:id'}>
          <HpLayout>
            <BlogDetailPage />
          </HpLayout>
        </Route>
        <Route path={'/categories/:category?'}>
          <HpLayout>
            <Categories />
          </HpLayout>
        </Route>
        {/* <Route component={PageNotFound} /> */}
        <Route>
          <HpLayout>
            <PageNotFound />
          </HpLayout>
        </Route>
      </Switch>
    </Router>
  )
}

export default OndcRoutes
