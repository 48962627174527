import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useGet from 'hooks/useGet'
import { getOrCreateDeviceId, getUserId } from 'helper'
import CustomButton from 'components/customButton'
import ForwardIcon from 'assets/svg/HPSvgs/ForwardIcon'
import ProductImage from 'assets/images/no_image_found.png'
import {
  Container,
  ContentSection,
  Content,
  ButtonWrapper,
  ProductImageSection,
  ImageSection,
} from 'styles/hpLayout/festiveSection'

const FestiveSection = () => {
  const history = useHistory()
  const [deviceId, setDeviceId] = useState()

  const execute = async () => {
    const deviceId = await getOrCreateDeviceId()
    setDeviceId(deviceId)
  }

  const userId = getUserId()
  const reqParams = {
    userId: userId,
    deviceId: deviceId,
  }

  const { data: latestProductData, refetch: fetchLatestProducts } = useGet(
    'latest_products',
    `/clientApis/v2/latest_products`,
    reqParams,
    { enabled: !!deviceId },
  )

  useEffect(() => {
    execute()
    if (deviceId) fetchLatestProducts()
  }, [deviceId, fetchLatestProducts])

  const latestProduct = latestProductData?.data?.length > 1 ? latestProductData.data[1] : null

  if (!latestProduct) return null

  return (
    <Container>
      <ContentSection>
        <Content>Gift Himalayan Purity this Festive Season</Content>
        <ButtonWrapper>
          <CustomButton
            label="Shop Now"
            variant="contained1"
            icon={<ForwardIcon />}
            onClick={() => {
              history.push(`products?productId=${latestProduct?.id}`)
            }}
          />
        </ButtonWrapper>
      </ContentSection>
      <ProductImageSection>
        <ImageSection>
          <img
            src={
              latestProduct?.item_details?.descriptor?.symbol
                ? latestProduct?.item_details?.descriptor?.symbol
                : ProductImage
            }
            onClick={() => {
              history.push(`products?productId=${latestProduct?.id}`)
            }}
            alt={latestProduct?.item_details?.descriptor?.name}
            loading="lazy"
            onError={(e) => {
              e.target.src =
                'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
            }}
          />
        </ImageSection>
        <ImageSection>
          <img
            src={
              latestProduct?.item_details?.descriptor?.images[0]
                ? latestProduct?.item_details?.descriptor?.images[0]
                : ProductImage
            }
            onClick={() => {
              history.push(`products?productId=${latestProduct?.id}`)
            }}
            alt={latestProduct?.item_details?.descriptor?.name}
            loading="lazy"
            onError={(e) => {
              e.target.src =
                'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
            }}
          />
        </ImageSection>
      </ProductImageSection>
    </Container>
  )
}

export default FestiveSection
