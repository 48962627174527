import React, { useState, useEffect } from 'react'
import useGet from 'hooks/useGet'
import Rating from '@mui/material/Rating'
import Pagination from '@mui/material/Pagination'
import moment from 'moment'
import ModalComponent from 'components/common/Modal'
import UserImage from 'assets/images/user-image.jpeg'

import {
  ReviewsSection,
  ProductDetailHeading,
  ReviewWrapper,
  ReviewDetailWrapper,
  UserDetailWrapper,
  UserImageWrapper,
  UserName,
  ReviewDetailContainer,
  RatingWrapper,
  ReviewImageWrapper,
  Review,
  ReviewDate,
  PaginationWrapper,
} from 'components/application/product-list/product-details/style'

const ProductReviews = (itemId) => {
  const [pagination, setPagination] = useState({
    currentPage: 1,
    postPerPage: 10,
  })
  const [totalOrdersCount, setTotalOrdersCount] = useState(0)
  const [selectedImage, setSelectedImage] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { data: reviewsData, refetch: fetchReviews } = useGet(
    'getReviews',
    `/clientApis/v2/reviews/${itemId?.itemId}?page=${pagination.currentPage}&limit=${pagination.postPerPage}`,
  )
  const reviews = reviewsData?.data
  useEffect(() => {
    if (reviewsData) {
      setTotalOrdersCount(reviewsData?.pagination?.totalReviews || 0)
    }
  }, [reviewsData])

  useEffect(() => {
    fetchReviews()
  }, [pagination.currentPage, itemId])

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setSelectedImage(null)
  }

  return (
    <>
      {reviews?.length > 0 && (
        <ReviewsSection>
          <ProductDetailHeading>Reviews</ProductDetailHeading>
          {reviews?.map((item, index) => (
            <ReviewWrapper key={index}>
              <ReviewDetailWrapper>
                <UserDetailWrapper>
                  <UserImageWrapper>
                    <img
                      src={item?.userDetails?.userImage ? item?.userDetails?.userImage : UserImage}
                      alt={item?.userDetails?.userName}
                      onError={(e) => {
                        e.target.src =
                          'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                      }}
                    />
                  </UserImageWrapper>
                  <UserName>{item?.userDetails?.userName}</UserName>
                </UserDetailWrapper>
                <ReviewDetailContainer>
                  <RatingWrapper>
                    <Rating name={`rating-${index}`} value={item?.rating || 0} precision={0.5} readOnly />
                  </RatingWrapper>
                  <ReviewImageWrapper>
                    {item?.imageUrl && (
                      <img
                        src={item?.imageUrl}
                        alt="review-image"
                        onError={(e) => {
                          e.target.src =
                            'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                        }}
                        onClick={() => handleImageClick(item?.imageUrl)}
                      />
                    )}
                  </ReviewImageWrapper>
                  {item?.review && (
                    <Review>
                      Comment: <span>{item?.review}</span>
                    </Review>
                  )}
                  <ReviewDate>{moment(item?.updatedAt).format('ll')}</ReviewDate>
                </ReviewDetailContainer>
              </ReviewDetailWrapper>
            </ReviewWrapper>
          ))}
        </ReviewsSection>
      )}
      {reviews?.length > 0 && totalOrdersCount > pagination.postPerPage && (
        <PaginationWrapper>
          <Pagination
            count={Math.ceil(totalOrdersCount / pagination.postPerPage)}
            shape="rounded"
            color="primary"
            page={pagination.currentPage}
            onChange={(event, currentPage) => {
              event
              setPagination((prevState) => ({ ...prevState, currentPage }))
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
          />
        </PaginationWrapper>
      )}
      <ModalComponent open={isModalOpen} onClose={closeModal} title="Image Preview">
        {selectedImage && <img src={selectedImage} alt="Selected Review" style={{ width: '100%' }} />}
      </ModalComponent>
    </>
  )
}

export default ProductReviews
