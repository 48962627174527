import React, { useState, useEffect, useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { TextField, Rating } from '@mui/material'
import Button from '@mui/material/Button'
import { getOrderDetailsRequest } from 'api/orders.api'
import useCancellablePromise from 'api/cancelRequest'
import { ToastContext } from 'context/toastContext'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import usePost from 'hooks/usePost'
import { yupResolver } from '@hookform/resolvers/yup'
import { createReviewFormValidation } from 'utils/Validation/reviewForm-validation'

import {
  ReviewsMainContainer,
  LeftContainer,
  ReviewRightContainer,
  Wrapper,
  FormWrapper,
  Content,
  Title,
  ReviewFormSubTitle,
  ButtonContainer,
  HelpText,
  ItemsSection,
  ItemDetailSection,
  ImageWrapper,
  ItemName,
  ErrorMessage,
} from 'styles/Feedback'

const RatingsAndReviews = () => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  isLoading
  const [orderDetails, setOrderDetails] = useState([])
  const [uploadedImages, setUploadedImages] = useState({})
  const dispatch = useContext(ToastContext)
  const schema = createReviewFormValidation(orderDetails?.items?.length || 0, uploadedImages)
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
  })
  const { mutateAsync } = usePost()

  const handleFileUpload = async (event, itemId, index) => {
    const file = event.target.files[0]

    if (!file) return

    try {
      const formData = new FormData()

      formData.append('file', file)
      formData.append('file_type', file?.type)

      const res = await mutateAsync({
        url: `/clientApis/v2/getSignUrlForUpload/${itemId}`,
        method: 'POST',
        payload: formData,
      })
      if (res && res.success && res.publicUrl) {
        const uploadedImageUrl = res.publicUrl
        setUploadedImages((prev) => ({ ...prev, [index]: uploadedImageUrl }))
      }
    } catch (err) {
      err
    }
  }

  const onSubmit = async (data) => {
    const payload = orderDetails?.items?.map((item, index) => ({
      orderId: id,
      itemId: item.product.id,
      review: data[`feedback_${index}`],
      rating: data[`rating_${index}`],
      imageUrl: uploadedImages[index] || null,
    }))

    try {
      const res = await mutateAsync({
        url: `clientApis/v2/review/${id}`,
        method: 'POST',
        payload: payload,
      })
      if (res) {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: 'Review has been created succesfully',
          },
        })
        reset()
        setUploadedImages({})
      }
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error?.response?.data?.message,
        },
      })
    }

    reset()
    setUploadedImages({})
  }

  const { cancellablePromise } = useCancellablePromise()

  useEffect(() => {
    if (id) {
      getOrderDetails(true)
    }
  }, [id])

  const getOrderDetails = async (isLoading = true) => {
    if (isLoading) {
      setIsLoading(true)
    }

    try {
      const data = await cancellablePromise(getOrderDetailsRequest(id))
      setOrderDetails(data[0])
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ReviewsMainContainer>
      <LeftContainer />
      <ReviewRightContainer>
        <Wrapper>
          <FormWrapper onSubmit={handleSubmit(onSubmit)}>
            <Content>
              <ReviewFormSubTitle>
                We fulfilled your order from our store and would love to get your feedback.
              </ReviewFormSubTitle>
            </Content>
            {orderDetails?.items?.map((item, index) => {
              return (
                <ItemsSection key={index}>
                  <ItemDetailSection>
                    <ImageWrapper>
                      <img src={item?.product?.descriptor?.symbol} alt={item?.product?.descriptor?.name} />
                    </ImageWrapper>
                    <ItemName>{item?.product?.descriptor?.name}</ItemName>
                  </ItemDetailSection>
                  <Content>
                    <Title>
                      Your Rating <span className="astreik">*</span>
                    </Title>
                    <Controller
                      name={`rating_${index}`}
                      control={control}
                      defaultValue={0}
                      render={({ field }) => <Rating {...field} size="large" />}
                    />
                    {errors[`rating_${index}`] && <ErrorMessage>{errors[`rating_${index}`]?.message}</ErrorMessage>}
                  </Content>
                  <Content>
                    <Title>Upload an Image (Optional)</Title>
                    <Controller
                      name={`image_${index}`}
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <>
                          <Button variant="outlined" component="label">
                            Upload Image
                            <input
                              type="file"
                              accept="image/*"
                              hidden
                              onChange={(e) => {
                                handleFileUpload(e, item.product.id, index)
                                field.onChange(e.target.files[0])
                              }}
                            />
                          </Button>
                          {uploadedImages[index] && (
                            <Title>
                              Uploaded Image URL:{' '}
                              <a href={uploadedImages[index]} target="_blank" rel="noopener noreferrer">
                                {uploadedImages[index]}
                              </a>
                            </Title>
                          )}
                        </>
                      )}
                    />
                  </Content>
                  <Content>
                    <Title>Feedback</Title>
                    <Controller
                      name={`feedback_${index}`}
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          rows={3}
                          multiline
                          variant="outlined"
                          fullWidth
                          placeholder="Share your recommendations..."
                        />
                      )}
                    />
                  </Content>
                </ItemsSection>
              )
            })}

            <ButtonContainer>
              <Button variant="contained" type="submit">
                Submit Review
              </Button>
            </ButtonContainer>
          </FormWrapper>
          <HelpText>
            Need Help? Ask at <span>Info@himira.co.in</span> or visit our <span>help center</span>
          </HelpText>
        </Wrapper>
      </ReviewRightContainer>
    </ReviewsMainContainer>
  )
}

export default RatingsAndReviews
