import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import ReactGA from 'react-ga4'
import { yupResolver } from '@hookform/resolvers/yup'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { RadioGroup } from '@mui/material'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Cookies from 'js-cookie'

import {
  ProfileMainWrapper,
  FormTitle,
  FormWrapper,
  UserWrapper,
  UserImageWrapper,
  SupportedImageFormats,
  UserInformationWrapper,
  FieldWrapper,
  Title,
  InputFieldWrapper,
  ButtonWrapper,
  ErrorMessage,
} from 'styles/UserProfile'
import useGet from 'hooks/useGet'
import usePost from 'hooks/usePost'
import {
  AddressDetail,
  AddressDetailSection,
  ButtonSection,
  DetailWrapper,
  UserAddressWrapper,
  UserName,
} from 'components/checkout/style'
import DeleteAddressModal from 'views/AddressDeleteModal/index'
import AddAddressModal from 'views/AddAddressModal'
import EditAddressModal from 'views/EditAddressModal'
import ModalComponent from 'components/common/Modal'
import { getUser } from 'utils/validateToken'
import { getSelectCall } from 'api/axios'
import useCancellablePromise from 'api/cancelRequest'
import { ToastContext } from 'context/toastContext'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import { USER_INFO_VALIDATION } from 'utils/Validation/userInfo-validation'
import UserImage from 'assets/images/user-image.jpeg'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const UserProfile = () => {
  const user = getUser()
  const dispatch = useContext(ToastContext)
  const { mutateAsync } = usePost()
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(USER_INFO_VALIDATION),
  })

  const { data: deliveryAddress, refetch: fetchDeliveryAddress } = useGet(
    'delivery_address',
    `/clientApis/v1/delivery_address`,
  )
  const { cancellablePromise } = useCancellablePromise()
  const [deleteModal, setDeleteModal] = useState(false)
  const [editForm, setEditForm] = useState(false)
  const [deleteAddressId, setDeleteAddressId] = useState('')
  const [addressId, setAddressId] = useState(false)
  const [addForm, setAddForm] = useState(false)
  const [imgLink, setImgLink] = useState(false)
  const [isModified, setIsModified] = useState(false)
  const [initialUserName, setInitialUserName] = useState('')
  const [initialEmail, setInitialEmail] = useState('')
  const [initialPhone, setInitialPhone] = useState('')
  const [signInType, setSignInType] = useState('')
  const [userId, setUserId] = useState(null)

  const handleInputChange = () => {
    setIsModified(true)
  }

  const updateInfo = (data) => {
    const userCookie = Cookies.get('user')
    if (userCookie) {
      const user = JSON.parse(userCookie)

      user.phone = data?.phone
      user.email = data?.email
      user.name = data?.name

      const updatedUserCookie = JSON.stringify(user)

      Cookies.set('user', updatedUserCookie)
    }
  }

  useEffect(() => {
    fetchDeliveryAddress()
  }, [deliveryAddress])

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'My Orders' })
  }, [])

  useEffect(() => {
    getUserInfo()
  }, [])

  const getUserInfo = async () => {
    ReactGA.event({
      category: 'Sidebar',
      action: 'Click',
      label: 'Profile Update',
    })
    try {
      const res = await cancellablePromise(getSelectCall(`/clientApis/getUserProfile`))
      const userData = res.data
      setSignInType(userData?.signup_with)
      const checkCode = res.data.phone.includes('+91')
      setUserId(userData?.userId)
      const phoneNumberWithCountryCode = checkCode ? userData?.phone : `+91${userData?.phone}`
      setImgLink(userData.userImage)
      setInitialUserName(userData?.userName)
      setInitialEmail(userData?.email)

      setInitialPhone(userData?.phone)
      setValue('userName', userData?.userName)
      setValue('phone', phoneNumberWithCountryCode)
      setValue('email', userData?.email)
      setValue('userImage', userData?.userImage)
      setValue('building', userData?.address.building)
      setValue('locality', userData?.address.locality)
      setValue('city', userData?.address.city)
      setValue('state', userData?.address.state)
      setValue('country', userData?.address.country)
      setValue('pincode', userData?.address.areaCode)
      const data = { phone: phoneNumberWithCountryCode, email: userData?.email, name: userData?.userName }
      updateInfo(data)
    } catch (err) {
      if (err.response.status !== 401)
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: err?.response?.data?.message,
          },
        })
    }
  }

  function removePlusSign(phoneNumber) {
    if (phoneNumber.startsWith('+')) {
      return phoneNumber.slice(1)
    }

    return phoneNumber
  }

  const updateProfile = async (data, type) => {
    let payload = {
      userName: data?.userName,
    }

    if (type === 'Hp') {
      payload = {
        userName: data?.userName,
        type: type,
        email: data?.email.toLowerCase(),
        phone: `${data.phone}`,
      }
    }

    try {
      const res = await mutateAsync({
        url: '/clientApis/userProfile',
        payload: payload,
      })

      updateInfo(res?.data)

      // AddCookie('phone', JSON.stringify({ phone: res?.data?.phone }))
      if (res?.message) {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: 'User information updated successfully',
          },
        })
      }
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.message,
        },
      })
    }
  }

  const onSubmit = async (data) => {
    const initialPhoneWithoutPlus = removePlusSign(initialPhone)
    const dataPhoneWithoutPlus = removePlusSign(data?.phone)
    const isUserNameChanged = data?.userName !== initialUserName
    const isPhoneChanged = dataPhoneWithoutPlus !== initialPhoneWithoutPlus
    const isEmailChanged = data?.email !== initialEmail

    if (!isUserNameChanged && !isPhoneChanged && !isEmailChanged) {
      return // Early return if nothing has changed
    }

    if (isUserNameChanged && !isPhoneChanged && !isEmailChanged) {
      updateProfile(data) // Update only profile if username changed but phone and email remain the same
    } else if (isPhoneChanged || isEmailChanged) {
      updateProfile(data, 'Hp') // Trigger update with 'Hp' type if phone or email changed
    }
  }
  const handleEditAddress = (id, address, descriptor) => {
    setEditForm(true)
    setAddressId({ id, address, user, descriptor })
  }

  const handleDelete = (e, id) => {
    setDeleteModal(true)
    setDeleteAddressId(id)
    e.preventDefault()
    e.stopPropagation()
  }

  const handleFileUpload = async (event) => {
    const file = event.target.files[0]

    if (!file) return

    try {
      // Create a new FormData object
      const formData = new FormData()

      // Append the file to the FormData object
      formData.append('file', file)
      formData.append('file_type', file?.type)

      // Call the upload API endpoint
      const res = await mutateAsync({
        url: `/clientApis/v2/getSignUrlForUpload/${userId}`,
        method: 'POST',
        payload: formData,
      })

      // Check if response data exists before accessing properties
      if (res && res.success && res.publicUrl) {
        const uploadedImageUrl = res.publicUrl
        setImgLink(uploadedImageUrl)
        const uploadProfile = async (data) => {
          const payload = {
            userImage: data.imageUrl ?? imgLink,
          }
          try {
            const res = await mutateAsync({
              url: '/clientApis/userProfile',
              payload: payload,
            })

            if (res?.message) {
              dispatch({
                type: toast_actions.ADD_TOAST,
                payload: {
                  id: Math.floor(Math.random() * 100),
                  type: toast_types.success,
                  message: 'User information updated successfully',
                },
              })
            }
          } catch (err) {
            dispatch({
              type: toast_actions.ADD_TOAST,
              payload: {
                id: Math.floor(Math.random() * 100),
                type: toast_types.error,
                message: err?.response?.data?.message,
              },
            })
          }
        }

        uploadProfile({ imageUrl: uploadedImageUrl })
      }
    } catch (err) {
      err
    }
  }

  return (
    <ProfileMainWrapper>
      <FormTitle>Personal Details</FormTitle>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <UserWrapper>
          <UserImageWrapper>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<img src={imgLink ? imgLink : UserImage} alt="Profile" />}
            >
              <VisuallyHiddenInput
                type="file"
                accept="image/*"
                onChange={(event) => {
                  handleFileUpload(event)
                  handleInputChange(event)
                }}
              />
            </Button>
            <SupportedImageFormats>
              Supported Formats: jpg, jpeg, png. You can upload image upto 10 mb.
            </SupportedImageFormats>
          </UserImageWrapper>
          <UserInformationWrapper>
            <FieldWrapper>
              <Title>
                User Name<span className="asterik">*</span>
              </Title>
              <InputFieldWrapper>
                <Controller
                  name="userName"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      type="text"
                      id="userName"
                      placeholder="Enter User Name"
                      {...field}
                      style={{ width: '100%' }}
                      onChange={(value) => {
                        handleInputChange()
                        field.onChange(value)
                      }}
                    />
                  )}
                />
                <ErrorMessage>{errors?.userName?.message}</ErrorMessage>
              </InputFieldWrapper>
            </FieldWrapper>
            <FieldWrapper>
              <Title>
                Email<span className="asterik">*</span>
              </Title>
              <InputFieldWrapper>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      disabled={signInType === 'email'}
                      type="text"
                      id="email"
                      placeholder="Enter Email"
                      {...field}
                      style={{ width: '100%' }}
                      onChange={(value) => {
                        handleInputChange()
                        field.onChange(value)
                      }}
                    />
                  )}
                />
                <ErrorMessage>{errors?.email?.message}</ErrorMessage>
              </InputFieldWrapper>
            </FieldWrapper>
            <FieldWrapper>
              <Title>
                Phone Number<span className="asterik">*</span>
              </Title>
              <InputFieldWrapper>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      disabled={signInType === 'phone'}
                      countryCodeEditable={false}
                      country="in"
                      value={value}
                      onChange={(phoneNumber) => {
                        onChange(phoneNumber)
                        handleInputChange(phoneNumber)
                      }}
                      className="phone-input"
                      disableDropdown
                      placeholder="Enter Phone Number"
                      inputStyle={{ boxShadow: 'none' }}
                      inputProps={{
                        name: 'phone',
                        autoFocus: false,
                        focus: false,
                      }}
                    />
                  )}
                />

                <ErrorMessage>{errors?.phone?.message}</ErrorMessage>
              </InputFieldWrapper>
            </FieldWrapper>
          </UserInformationWrapper>
        </UserWrapper>

        <ButtonWrapper>
          <Button variant="contained" type="submit" disabled={!isModified || !isValid}>
            Save Changes
          </Button>
        </ButtonWrapper>
      </FormWrapper>
      {deliveryAddress && deliveryAddress.length > 0 && (
        <UserAddressWrapper>
          <FormTitle>Your Addresses</FormTitle>

          <RadioGroup className="address-wrapper">
            {deliveryAddress.map((item, index) => {
              const { id, address, descriptor } = item
              if (!address) {
                return null
              }

              return (
                <AddressDetailSection key={index}>
                  <DetailWrapper>
                    <UserName>{address?.name}</UserName>
                  </DetailWrapper>
                  <AddressDetail>{`${address?.building}, ${address?.locality}, ${address?.state}`}</AddressDetail>
                  <AddressDetail>{address?.areaCode}</AddressDetail>
                  <ButtonSection>
                    <Button
                      variant="outlined"
                      className="edit-button"
                      onClick={() => {
                        handleEditAddress(id, address, descriptor)
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={(e) => {
                        handleDelete(e, id)
                      }}
                    >
                      Delete
                    </Button>
                  </ButtonSection>
                </AddressDetailSection>
              )
            })}
          </RadioGroup>
        </UserAddressWrapper>
      )}
      {deleteModal && (
        <ModalComponent
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          title={'Delete Address'}
          titleBg={true}
        >
          <DeleteAddressModal
            onClose={() => setDeleteModal(false)}
            id={deleteAddressId}
            fetchDeliveryAddress={fetchDeliveryAddress}
          />
        </ModalComponent>
      )}
      {addForm && (
        <ModalComponent open={addForm} onClose={() => setAddForm(false)} title="Add New Address">
          <AddAddressModal onClose={() => setAddForm(false)} />
        </ModalComponent>
      )}
      {editForm && (
        <ModalComponent open={editForm} onClose={() => setEditForm(false)} title="Edit Your Address">
          <EditAddressModal onClose={() => setEditForm(false)} addressDetails={addressId} />
        </ModalComponent>
      )}
    </ProfileMainWrapper>
  )
}

export default UserProfile
