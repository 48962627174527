import React from 'react'
import useStyles from './style'
import ReactGA from 'react-ga4'
import Button from '@mui/material/Button'
import { useHistory } from 'react-router-dom'
import moment from 'moment/moment'
import ProductImage from 'assets/images/no_image_found.png'
import { EmptyOrders } from 'components/application/cart/empty-cart'

import {
  OrderDeatilWrapper,
  OrderWrapper,
  OrderIdWrapper,
  OrderId,
  OrderDetailWrapper,
  DetailWrapper,
  Title,
  Content,
  OrderHistoryWrapper,
  Wrapper,
  OrderImageWrapper,
  ProductDetailWrapper,
  ProductTitle,
  StoreTitle,
  QunatityWrapper,
  QuantityTitle,
  ProductQuantity,
  ButtonWrapper,
  ReviewTitle,
} from 'styles/OrderHistory'

const OrderCard = ({ orderDetails }) => {
  const classes = useStyles()
  const history = useHistory()

  if (!orderDetails && orderDetails?.length === 0) {
    return (
      <OrderDeatilWrapper>
        <EmptyOrders />
      </OrderDeatilWrapper>
    )
  }

  return (
    <OrderDeatilWrapper>
      {orderDetails?.map((item, index) => {
        const deliveryType = item?.fulfillments?.find((f) => f?.type === 'Delivery')
        const tat = deliveryType ? moment.duration(deliveryType['@ondc/org/TAT']) : null
        const days = tat.asDays()
        const futureDate = moment(item?.createdAt).add(days, 'days')
        const formattedDate = futureDate.format('MMMM Do, YYYY')
        const { items } = item
        const { descriptor } = item.provider
        return (
          <OrderDeatilWrapper key={index}>
            <OrderWrapper>
              <OrderIdWrapper>
                <OrderId>Order number :</OrderId>
                <OrderId>{item?.id}</OrderId>
              </OrderIdWrapper>
              <OrderDetailWrapper>
                <DetailWrapper>
                  <Title>Order On : </Title>
                  <Content>
                    {moment(item?.createdAt).format('MMMM Do, YYYY')} at {moment(item?.createdAt).format('hh:mma')}
                  </Content>
                </DetailWrapper>
                <DetailWrapper>
                  <Title>Order Status: </Title>
                  <Content>{item?.items[0]?.fulfillment_status}</Content>
                </DetailWrapper>
              </OrderDetailWrapper>
              <OrderDetailWrapper>
                <DetailWrapper>
                  <Title>Estimated Delivery : </Title>
                  <Content>{formattedDate}</Content>
                </DetailWrapper>
                <DetailWrapper>
                  <Title>Payment Method : </Title>
                  <Content>Prepaid</Content>
                </DetailWrapper>
              </OrderDetailWrapper>
            </OrderWrapper>
            <OrderHistoryWrapper>
              {items
                ?.filter((item) => item?.quantity?.count > 0)
                ?.map((item, index) => {
                  return (
                    <Wrapper key={index}>
                      <OrderImageWrapper>
                        <img
                          src={
                            item?.product?.descriptor?.images?.length > 0
                              ? item?.product?.descriptor?.images[0]
                              : ProductImage
                          }
                          onError={(e) => {
                            e.target.src =
                              'https:www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                          }}
                          alt="product-image"
                        />
                      </OrderImageWrapper>
                      <ProductDetailWrapper>
                        <ProductTitle>{item?.product?.descriptor?.name}</ProductTitle>
                        <StoreTitle>{descriptor?.name}</StoreTitle>
                        <QunatityWrapper>
                          <QuantityTitle>Quantity:</QuantityTitle>
                          <ProductQuantity>
                            {item?.quantity?.count ? item?.quantity?.count : item?.originalCount}
                            <span>
                              {(item?.return_status || item?.cancellation_status) && (
                                <div>(Status: {item?.return_status || item?.cancellation_status})</div>
                              )}
                            </span>
                          </ProductQuantity>
                        </QunatityWrapper>
                      </ProductDetailWrapper>
                    </Wrapper>
                  )
                })}
              <QunatityWrapper>
                {item?.state === 'Completed' && (
                  <ReviewTitle
                    onClick={() => {
                      history.push(`/ratings/${item?.id}`)
                    }}
                  >
                    Write Product Review
                  </ReviewTitle>
                )}
              </QunatityWrapper>
              <ButtonWrapper>
                <Button
                  className={classes.viewSummaryButton}
                  variant="contained"
                  onClick={() => {
                    history.push(`/order/${item?.id}`)
                    ReactGA.event({
                      category: 'Sidebar',
                      action: 'Click',
                      label: 'View Order Details',
                    })
                  }}
                >
                  View Details
                </Button>
              </ButtonWrapper>
            </OrderHistoryWrapper>
          </OrderDeatilWrapper>
        )
      })}
    </OrderDeatilWrapper>
  )
}

export default OrderCard
